@use 'src/theme/helpers' as *;
@use 'src/theme/breakpoints.module' as *;
@use 'src/theme/color.module' as *;
@use 'src/theme/spacings.module' as *;

.container {
  max-width: $max-width-section-xl;
  margin: auto;
  background: var(--for-sec-red_ora);
  background-size: auto 250%;
  background-position: 63% 50%;
  border-radius: var(--cor_rad-lg);
  padding: pxToRem(80) pxToRem(32) 0;
  text-align: center;
}

.title {
  margin: 0;
}

.content {
  margin-top: pxToRem(8);
}

.footer {
  margin-top: pxToRem(16);
  margin-bottom: pxToRem(32);
  display: flex;
  flex-direction: column;
  gap: pxToRem(8);
}

.imageWrapper {
  --imageOffset: #{pxToRem(40)};
  margin: calc(-1 * var(--imageOffset)) auto 0;
  transform: translateY(var(--imageOffset));

  img {
    width: 100%;
    height: 100%;
    display: block;
    object-fit: contain;
  }
}

@include sm {
  .container {
    background-position: center;
    background-size: 150%;
    padding-top: pxToRem(32);
    padding-bottom: pxToRem(32);
    display: grid;
    grid-template: 1fr / 1fr 1fr;
    column-gap: pxToRem(32);
  }

  .reverse {
    direction: rtl;

    .contentWrapper {
      direction: ltr;
    }
  }

  .contentWrapper {
    align-self: center;
  }

  .footer {
    margin-bottom: 0;
    flex-direction: row;
    justify-content: center;
  }

  .imageWrapper {
    height: 100%;
    margin: 0;
    transform: none;

    > span {
      height: 100% !important;
    }
  }

  .imageWrapper {
    --imageOffset: #{pxToRem(64)};
  }
}

@include lg {
  .container {
    background-size: 100%;
  }
}
