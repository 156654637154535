@use 'src/theme/helpers' as *;
@use 'src/theme/breakpoints.module' as *;
@use 'src/theme/color.module' as *;
@use 'src/theme/spacings.module' as *;

.root {
  color: var(--tex-def-tie1);
  white-space: pre-line;
  text-align: center;
}

.table {
  width: 100%;
  table-layout: fixed;
  box-sizing: border-box;
  border-collapse: collapse;
}

.tableHeader {
  .headerCell {
    box-sizing: border-box;
    width: calc(100% / 3);
  }

  .columnLeft {
    border-top-left-radius: pxToRem(16);
  }

  .columnRight {
    border-top-right-radius: pxToRem(16);
  }
}

.tableBody {
  .cell:not(.headerCell) {
    vertical-align: top;
  }

  .headerCell {
    border-bottom: thin solid $color-neutral-200;
    text-align: left;
    padding-left: 0;
  }
}

.cell {
  padding: pxToRem(8);
  border-bottom: thin solid $color-opacity-light-highEmphasis;

  svg:not(.cellIcon) {
    width: pxToRem(55);
    height: pxToRem(18);
  }
}

.columnHeader {
  background-color: transparent;
}

.tableHeader .columnHeader {
  border: none;
}

.columnLeft {
  color: $color-opacity-light-highEmphasis;
  background-color: $color-sentimental-success-500;
}

.columnRight {
  background-color: $color-neutral-100;

  .cellIcon {
    fill: $color-sentimental-error-600;
  }
}

.cellText {
  margin: 0;
}

@include sm {
  .cell,
  .tableBody .headerCell {
    padding: pxToRem(16);
  }

  .cell svg:not(.cellIcon) {
    width: pxToRem(77);
    height: pxToRem(25);
  }
}

@include lg {
  .tableHeader {
    .columnHeader {
      width: pxToRem(290);
    }

    .columnLeft,
    .columnRight {
      width: calc((100% - pxToRem(290)) / 2);
    }
  }
}
