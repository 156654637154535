@use 'src/theme/helpers' as *;
@use 'src/theme/color.module' as *;

.promoContainer {
  z-index: 20;
  position: absolute;
  top: pxToRem(20);
  left: pxToRem(20);
  display: grid;
}

.promo {
  display: flex;
  gap: pxToRem(4);
  align-items: center;
  margin-right: auto;
  border-radius: var(--cor_rad-lg);
  padding: pxToRem(8) pxToRem(12);
  margin-bottom: pxToRem(8);
  background-color: #179da6;
  text-transform: uppercase;
  @include fontSize(12, 16);
  letter-spacing: 0.1em;
  color: #ffffff;
  font-weight: 900;
  &.myPurchase {
    background-color: $color-uiPrimary-200;
    color: $color-uiPrimary-900;
  }
}
