@use 'src/theme/helpers' as *;
@use 'src/theme/breakpoints.module' as *;
@use 'src/theme/color.module' as *;
@use 'src/theme/spacings.module' as *;

.root {
  display: flex;
  flex-direction: column-reverse;
  gap: pxToRem(16);
  justify-content: center;
}

@include md {
  .root {
    flex-direction: row;

    a {
      width: 50%;
    }
  }
}
