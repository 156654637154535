@use 'src/theme/helpers' as *;
@use 'src/theme/breakpoints.module' as *;
@use 'src/theme/color.module' as *;
@use 'src/theme/spacings.module' as *;

.contentWrapper {
  display: grid;
  grid-template-areas:
    '🚗'
    '🚑'
    '🚓';
  row-gap: pxToRem(40);
}

.header {
  grid-area: 🚗;
  color: var(--tex-def-tie1);
}

.title {
  margin: 0;
  text-align: center;
}

.logosWrapper {
  grid-area: 🚑;
  display: flex;
  justify-content: center;
  border-radius: var(--cor_rad-xs);
  overflow: hidden;
  width: fit-content;
  max-width: 100%;
  margin: auto;
}

.logo {
  width: pxToRem(120);
  aspect-ratio: 1/1;
  position: relative;
  display: block;
}

.subtext {
  grid-area: 🚓;
  text-align: center;
  color: var(--tex-def-tie1);
}

@include sm {
  .contentWrapper {
    grid-template-areas:
      '🚗 🚑'
      '🚓 🚓';
    row-gap: pxToRem(32);
    column-gap: pxToRem(16);
    width: fit-content;
    margin: auto;
  }

  .header {
    display: flex;
    align-items: center;
  }

  .title {
    max-width: pxToRem(216);
    text-align: start;
  }
}

@include md {
  .contentWrapper {
    row-gap: pxToRem(40);
    column-gap: pxToRem(32);
  }

  .title {
    max-width: pxToRem(264);
  }
}
