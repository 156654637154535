@use 'src/theme/helpers' as *;
@use 'src/theme/breakpoints.module' as *;
@use 'src/theme/color.module' as *;
@use 'src/theme/spacings.module' as *;

.header {
  margin-bottom: pxToRem(16);
}

.title {
  text-align: center;
  margin: 0;
}

.logosWrapper {
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-wrap: wrap;
  column-gap: pxToRem(16);
}

.logo {
  width: pxToRem(120);
  aspect-ratio: 1/1;
  display: flex;
  align-items: center;

  .logoImage {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
}

@include sm {
  .header {
    margin-bottom: pxToRem(10);
  }

  .logosWrapper {
    column-gap: pxToRem(32);
  }
}

@include xl {
  .header {
    margin-bottom: pxToRem(16);
  }

  .logosWrapper {
    column-gap: pxToRem(78);
  }

  .logo {
    width: pxToRem(180);
  }
}
